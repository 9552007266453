/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-11 15:59:07
 * @Module: 格调学院的layouts
 */
 <template>
  <div class="collegepage">
    <router-view />
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.collegepage {
  min-height: 100vh;
  border: solid 1px #f9f9f9;
  background: #f9f9f9;
  padding-top: 60px;
}
</style>